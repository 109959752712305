import React from 'react';
import { Title3 } from '../home/homeStyledComponents';
import { trackEvent } from '../../services/analytics';

const link = 'https://tillicouture.typeform.com/to/kldxT1';

const Declare = () => (
  <div>
    <Title3 margin>Déclarer un sinistre</Title3>
    <a
      target="_blank"
      rel="noreferrer noopener"
      href={link}
      onClick={() => trackEvent('click', 'assurance_declarer-sinistre')}
    >
      Un sinistre ? Clique-ici pour le déclarer
    </a>
  </div>
);

export default Declare;
