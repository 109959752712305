import React from 'react';
import styled from 'styled-components';
import { colors, mobileThresholdPixels } from '../styledComponents';

const TABLE = styled.table`
  border-spacing: 0px;
  margin-bottom: 10px;
`;

const TD = styled.td`
  font-weight: ${(props) => (props.header || props.bold) ? 'bold' : 'normal'};
  padding: 15px;
  text-align: ${(props) => props.first ? 'left' : 'center'};;
  border-bottom: ${(props) => props.lastRow ? '0px' : `1px solid ${colors.navy}`};
  ${(props) => props.header ? `border-bottom: 2px solid ${colors.navy};` : ''}
  border-right: 1px solid ${colors.navy};
  &:last-child {border-right: 0px};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 1px;
    font-size: 10px;
  }
`;

const TR = styled.tr`
  &:nth-child(even) {background-color: rgba(241, 230, 202, 0.3)};
  ${(props) => props.header ? 'background-color: rgba(190, 211, 212, 0.3);' : ''};
`;

const InsuranceTable = () => (
  <div>
    <TABLE>
      <thead>
        <TR header>
          <TD header>NATURE DES GARANTIES</TD>
          <TD header>LIMITES DES GARANTIES</TD>
          <TD header>
            FRANCHISES
            <br />
            {' '}
            par sinistre
          </TD>
        </TR>
      </thead>
      <tbody>
        <TR>
          <TD first>
            Tous dommages corporels, matériels et immatériels consécutifs confondus
          </TD>
          <TD>9.000.000€ par année d’assurance</TD>
          <TD>150€ sur tout dommage autre que corporel</TD>
        </TR>
        <TR>
          <TD first>
            Dont :
            <ul>
              <li>Dommages corporels</li>
              <li>Dommages matériels et immatériels consécutifs confondus</li>
            </ul>
          </TD>
          <TD>
            9.000.000€ par année d’assurance
            <br />
            1.200.000€ par année d’assurance
            <br />
            35.000€ par sinistre
          </TD>
          <TD />
        </TR>
        <TR>
          <TD first bold>Autres garanties :</TD>
          <TD />
          <TD />
        </TR>
        <TR>
          <TD first>Faute inexcusable(dommages corporels)</TD>
          <TD>2.000.000€ par année d’assurance dont 1.000.000€ par sinistre</TD>
          <TD>380€</TD>
        </TR>
        <TR>
          <TD first>
            Atteinte accidentelle à l’environnement(tous dommages confondus)
          </TD>
          <TD>750.000€ par année d’assurance</TD>
          <TD>100€ sur tout dommage autre que corporel</TD>
        </TR>
        <TR>
          <TD first>Dommages immatériels non consécutifs</TD>
          <TD>
            100.000€ par année d’assurance
            <br />
            {' '}
            35.000€ par sinistre
          </TD>
          <TD />
        </TR>
        <TR>
          <TD first>
            Dommages aux biens confiés
            <br />
            {' '}
            (selon extension aux conditions particulières)
            y. c. frais de reconstitution de documents/ médias confiés
          </TD>
          <TD>
            100.000€ par année d’assurance
            <br />
            {' '}
            35.000€ par sinistre
          </TD>
          <TD />
        </TR>
        <TR>
          <TD first>Défense</TD>
          <TD>Inclus dans la garantie mise en jeu</TD>
          <TD>Selon la franchise de la garantie mise en jeu</TD>
        </TR>
        <TR>
          <TD first>Recours</TD>
          <TD>20.000€ par litige</TD>
          <TD>
            Seuil d’intervention :
            <br />
            {' '}
            380€
          </TD>
        </TR>
        <TR>
          <TD lastRow first>Responsabilité environnementale</TD>
          <TD lastRow>35 000€ par année d’assurance</TD>
          <TD lastRow>1 500€ par sinistre</TD>
        </TR>
      </tbody>
    </TABLE>
  </div>
);

export default InsuranceTable;
